.button-waze {
    display: flex;
    border: 2px solid #F55A2A;
    background-color: #fff;
    padding: 9px 0;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-evenly;
    max-width: 68px;
    margin: 0 0 4px 0;

    img {
        width: 20px;
        height: 20px;
    }
}