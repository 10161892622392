.route {
    margin-bottom: 20px;

    .label-route {
        font-size: 26px;
        margin-right: 34px;
    }

    .star-icon-wrapper {
        display: flex;
        min-height: 74px;

        .star-icon {
            height: 40px;
            margin-top: 16px;
        }


        .triangle {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 20px solid #CCCC;
            margin: 1em 0.9em 0em 0em;
            position: absolute;

            .inner-triangle {
                position: relative;
                top: -20px;
                right: 2px;
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 20px solid #F3F3F3;
            }
        }

        .route-container {
            display: flex;
            justify-content: space-around;
            flex-grow: 1;
            align-items: center;
            border-radius: 8px;
            background-color: #F7F7F7;
            margin-right: 6px;
            padding: 12px 12px;
        }

        .station {
            display: flex;
            align-items: center;

            .count-window {
                width: 70px;
                height: 50px;
                border-radius: 10px;
                background-color: white;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .locker {
                    height: 26px;
                }
            }

            .window-text {
                max-width: 56px;
                line-height: 1;
                margin-right: 10px;
                color: #fff;
            }

            .orange {
                color: #F6683C
            }
        }

        .selected {
            background-color: #F6683C;
        }
    }
}

@media screen and (max-width: 800px) {
    .route-container .btn-select-route {
        padding: 8px 8px;
    }
}