.notification-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .notification {
        position: fixed;
        z-index: 3;
        top: 22%;
        left: 35%;
        background-color: white;
        padding: 2px 8px;
        font-size: 22px;
        width: 400px;
        max-height: 600px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: -1px 6px 18px -7px rgb(0 0 0 / 75%);

        .text {
            text-align: center;
            padding: 12px 0 0 0;
        }

        .btn-close {
            display: flex;
            justify-content: center;
            margin-bottom: 8px;
            margin: 16px 0px 12px 0;

            .button-white {
                padding: 8px 22px;
            }
        }
    }
}


@media (max-width: 768px) {
    .notification-wrapper .notification {
        top: 20%;
        left: 4%;
        width: 90%;
        font-size: 18px;
    }

    .notification-wrapper .notification .text {
        padding: 10px 0;
    }

    .notification-wrapper .notification .btn-close {
        margin: 12px 0px;
    }
}