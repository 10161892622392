.login-otp-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;

    .enter-text {
        text-align: center;
        margin-bottom: 20px;

        div:first-child {
            font-size: 30px;
            font-weight: bold;
            color: #333;
        }

        .underline {
            width: 60px;
            height: 4px;
            background-color: #fc5726;
            margin: 10px auto 0;
        }
    }

    form {
        width: 100%;
        max-width: 400px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .login-fail {
            background-color: #f8d7da;
            color: #842029;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #f5c2c7;
            margin-bottom: 15px;
            font-size: 22px;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;

            label {
                font-size: 22px;
                color: #555;
                margin-bottom: 12px;
            }

            input {
                direction: rtl;
                padding: 12px;
                font-size: 18px;
                border: 1px solid #ccc;
                border-radius: 4px;
                outline: none;

                &:focus {
                    border-color: #fc5726;
                    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
                }
            }
        }

        .btn-login-wrapper {
            text-align: center;

            .btn-back,
            .btn-login {
                background-color: #fc5726;
                color: #fff;
                padding: 10px 20px;
                margin: 0 20px;
                font-size: 18px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #fc673b;
                }

                &:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }
            }

            .btn-back {
                background-color: #fc3f43;
            }


        }
    }

    .logo {
        margin-top: 60px;
        width: 100px;
        height: auto;

        @media (min-width: 768px) {
            width: 100px;
        }
    }


    @media (max-width: 768px) {
        .enter-text div:first-child {
            font-size: 22px;
        }

        form {
            padding: 15px;

            .input-wrapper {
                input {
                    font-size: 18px;
                    padding: 16px;
                }
            }

            .btn-login-wrapper {

                .btn-back,
                .btn-login {
                    padding: 12px 18px;
                    font-size: 18px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .enter-text div:first-child {
            font-size: 20px;
        }

        form {
            padding: 10px;

            .input-wrapper {
                label {
                    font-size: 18px;
                }

                input {
                    font-size: 16px;
                    padding: 9px;
                }
            }

            .btn-login-wrapper {

                .btn-back,
                .btn-login {
                    padding: 8px 16px;
                    font-size: 18px;
                }
            }
        }

        .logo {
            width: 60px;
        }
    }
}