.storage-sacks {
    padding: 6px 12px;
    background-color: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    margin: 0px 22px 8px 22px;


    @media (max-width: 600px) {
        padding: 10px;

    }
}