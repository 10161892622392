.stations-wrapper {
    display: flex;
    flex-grow: 1;
    background-color: #F3F3F3;
    padding: 14px 0;
    margin-top: 70px;

    .stations-container {
        display: flex;
        flex-direction: column;
        background-color: #F3F3F3;
        width: 100%;
        padding: 0px 16px;
        overflow-y: auto;
        direction: ltr;

        .stations-list {
            display: flex;
            flex-direction: column;

            .station-empty {
                direction: rtl;
                font-size: 24px;
            }
        }

        .header {
            display: flex;
            align-items: center;
            margin: 8px 6px 0 6px;


            .search {
                direction: rtl;
                display: flex;
                flex-grow: 1;
                font-size: 18px;

                input {
                    border-radius: 8px;
                    font-size: 20px;
                    border: none;
                    padding: 4px 8px;
                    margin-right: 8px;
                }

                .no-location {
                    width: 3rem;
                }
            }
        }


        .line {
            border-bottom: 1px dashed #C5C5C5;
            margin-top: 8px;
        }
    }

    .single-station-wrapper:first-child {
        .border {
            margin: 1em -0.8em 0em 1em;
        }
    }

    .single-station-wrapper:last-child {
        .border {
            margin: 0em -0.8em 6em 1em;
        }
    }

    .notification-msg {
        padding: 8px 8px;
        font-size: 30px;

        div:first-child {
            text-decoration: underline;
        }

        div:nth-child(2) {
            line-height: 1;
        }
    }
}

.with-map {
    max-height: 100vh;
    min-height: 88vh;
}

.without-map {
    max-height: 100vh;
}


.stations-container::-webkit-scrollbar {
    width: 10px;
}

.stations-container::-webkit-scrollbar-track {
    background: #E2E2E2;
    border: 4px solid transparent;
    background-clip: content-box;

}

.stations-container::-webkit-scrollbar-thumb {
    background: #F55726;
    border-radius: 10px;
}



@media screen and (max-width: 800px) {
    .stations-wrapper {
        margin-top: 90px;
    }

    .with-map {
        max-height: 86vh !important;
    }

    .without-map {
        max-height: 100vh;
    }

    .header {
        .search {

            margin-left: 4px;
            align-items: center;

            input {
                border-radius: 8px;
                font-size: 16px;
                border: none;
                padding: 0 0px;
                margin-right: 4px;
                max-width: 160px;

            }
        }
    }
}


@media screen and (max-width: 400px) {
    .header {
        font-size: 12px;
        line-height: 1;
        padding: 2px 4px;
    }

}