.popup-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .popup {
        position: fixed;
        z-index: 3;
        top: 18%;
        left: 49%;
        background-color: wheat;
        padding: 2px 8px;
        font-size: 22px;

        .btn-close {
            cursor: pointer;
            direction: rtl;
            font-size: 20px;
        }
    }
}