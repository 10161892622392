.menu {
    .request-data {
        padding: 18px 24px;
        width: 56%;
        direction: ltr;

        .token {
            max-width: 400px;
            word-wrap: break-word;
        }
    }

    img {
        width: 30px;
    }

    .collapse {
        display: flex;
        flex-direction: row;
        position: absolute;
        direction: ltr;
        top: 76px;
        left: 0;
        width: 100%;
        height: 90vh;
        z-index: 5;

        .menu-drop {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            direction: rtl;
            width: 20%;
            background-color: #fff;
            padding: 20px 0;
            position: relative;
            overflow-y: auto;


            .request-data {
                font-size: 24px;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                padding: 16px 20px;
                border-top: 1px solid #e0dddf;
                transition: background 0.3s ease, transform 0.3s ease;
                line-height: 1;

                &:nth-child(4) {
                    border-bottom: 1px solid #e0dddf;
                }

                &:hover {
                    background-color: #f8f9fa;
                    transform: translateX(4px);
                }


            }

            .menu-drop:nth-child(4) {
                border-bottom: 1px solid #e0dddf;
            }

            .logout {
                position: absolute;
                display: flex;
                align-items: center;
                bottom: 0;
                width: 100%;
                border-top: 2px solid #e0dddf;
                font-size: 28px;
                padding: 4px 8px 0 0;

                img {
                    padding-right: 10px;
                }
            }

            .version {
                display: flex;
                justify-content: flex-start;
                color: #666;
                position: absolute;
                bottom: 76px;
                right: 8px;
                width: 100%;
            }
        }

        .menu-overlay {
            width: 80%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 1;
        }
    }

}

@media screen and (max-width: 800px) {
    .menu .collapse {
        width: 240%;
        height: 94vh;
    }
}

@media screen and (max-width: 430px) {
    .menu .collapse {
        height: 90vh;
    }

}