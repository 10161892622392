.header-wrapper {
    padding: 18px 16px;
    background-color: #fff;
    border-bottom: 2px solid #C2C2C2;
    display: flex;
    direction: rtl;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 98vw;
    z-index: 6;

    .header {
        display: flex;
        margin-right: 106px;

        .button-white {
            font-size: 16px;
            line-height: 1;
            padding: 9px 10px;
        }

        .btn-back {
            display: flex;
            line-height: 1;
            align-items: center;
        }

        .route-name {
            font-size: 26px;
            margin-right: 8px;
        }
    }



    .call {
        font-size: 30px;
        position: absolute;
        left: 7%;

        img {
            margin: 0 0px 1px 8px;
        }
    }

    .test-coordinates {
        flex-grow: 1;

        input {
            width: 80px;
        }
    }
}

@media screen and (max-width: 800px) {
    .header-wrapper {
        width: 96vw;

        .call {
            display: none;
        }


    }



}

@media screen and (max-width: 400px) {
    .header-wrapper {
        .header {
            margin-right: 112px;
        }

        .btn-back {
            flex-wrap: wrap;

        }
    }

}