.routes-wrapper {
    direction: rtl;
    display: flex;
    margin-top: 62px;

    .routes {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px 20px 20px 34px;
    }

    .grey-space {
        width: 20px;
        background-color: #F3F3F3;
        border-right: 1px solid #CCCCCC;
    }

    .welcome {
        line-height: 1.2;
        margin: 10px 0 40px 0;

        div:first-child {
            font-size: 34px;
            font-weight: bold;
        }

        div:nth-child(2) {
            font-size: 20px;
        }
    }

    .btn-start-day {
        display: flex;
        justify-content: center;
        margin-right: 28px;

        .button-white {
            font-size: 26px;
            padding: 16px 60px;
            border-radius: 16px;
        }
    }

    .disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }
}

.routes-holder {
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media screen and (max-width: 800px) {
    .routes-wrapper .btn-start-day .button-white {
        padding: 16px 46px;
    }
}