.button-white {
    border: 2px solid #F55726;
    padding: 4px 10px;
    border-radius: 8px;
    background-color: #ffff;
    color: #F55726;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

}