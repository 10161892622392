.barcodeScanner {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 15px;
    left: 7px;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5019607843);

    .barcode-wrapper::-webkit-scrollbar {
        width: 4px;
        overflow-y: auto;
    }

    .barcode-wrapper::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    .barcode-wrapper::-webkit-scrollbar-thumb {
        background-color: #F55726;
        border-radius: 5px;

    }

    .barcode-wrapper {
        position: fixed;
        text-align: center;
        z-index: 3;
        top: 4%;
        left: 27%;
        background-color: white;
        font-size: 22px;
        width: 520px;
        max-height: 650px;
        padding: 20px;
        border-radius: 8px;
        overflow-y: scroll;

        video {
            height: 340px;
        }

        .header {
            display: flex;

            .btn-close {
                margin-left: 20%;

                .button-white {
                    padding: 8px 10px;
                }
            }

            h1 {
                margin: 0px 0 12px 0;
            }
        }

        .order-details {
            display: flex;
            flex-direction: column;
            align-items: center;
        }



        .center-text {
            margin: 12px 0 0px 0;
        }

        .center-input {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            input {
                height: 26px;
                margin-bottom: 20px;
            }

            .btn-send {
                .button-white {
                    padding: 4px 12px;
                    margin-right: 8px;
                    border-color: #1ebbd7;
                    color: #1ebbd7;
                }
            }
        }

        .table-wrapper {
            margin-bottom: 46px;

            table {
                width: 100%;
                direction: rtl;
                border-collapse: collapse;
                margin-bottom: 20px;
            }

            th,
            td {
                padding: 10px;
                text-align: right;
                border: 1px solid #ddd;
            }

            tr:nth-child(1) {
                background-color: #A0A0A0;
            }

            tr:nth-child(even) {
                background-color: #f9f9f9;
            }

        }

        .order-not-found {
            color: red;
            font-size: 28px;

        }
    }

    @media (max-width: 850px) {
        .barcode-wrapper {
            left: 4%;
            max-height: 73vh;
            width: 82vw;
            // .barcode-reader {
            //     width: 580px;
            // }

            // video {
            //     width: 580px !important;
            //     height: 430px;
            // }
        }
    }
}