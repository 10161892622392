.single-order-wrapper {
    direction: rtl;
    display: flex;
    padding: 6px 4px;
    border-radius: 8px;
    margin: 0 0 8px 0;
    align-items: center;
    min-height: 56px;

    .order-number {
        font-size: 20px;
        padding: 0 12px 0 0;
        width: 48px;

    }

    .customer {
        font-size: 20px;
        line-height: 1.2;
        padding: 0 20px;
        width: 128px;
    }

    .logo-wrapper {
        min-width: 80px;
        text-align: center;

        .icon-logo {
            width: 48px;
        }

        div {
            line-height: 1;
            text-align: center;
            word-wrap: break-word;
        }

    }

    .separator-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        .separator {
            position: absolute;
            border: 1px solid #fff;
            height: 66px;
            margin-right: 10px;
        }
    }

    .order-data {
        font-size: 18px;

        .order-date {
            direction: ltr;
        }
    }


    .return-orders {
        display: flex;
        align-items: center;
    }

    .details {
        display: flex;
        direction: ltr;
        align-items: center;
        flex-grow: 1;
        padding: 0 12px;

        .sack {
            display: flex;
            align-items: center;
        }

        .bags-quantity {
            display: flex;
            margin-right: 8px;
        }

        .laundry-details {
            display: flex;
            align-items: center;
        }

        .hanging {
            display: flex;
            align-items: center;
            margin-right: 4px;
        }

        .sign-multi {
            display: flex;
            align-items: center;
            font-size: 24px;
            margin-right: 6px;
        }

        .items-count {
            font-size: 38px;
        }

        .icon-laundry {
            height: 28px;

        }

        .folded {
            display: flex;
            align-items: center;
        }


        .icon-folded {
            width: 54px;
            margin-left: -12px;
        }
    }

}

.pickup {
    background-color: #ec6b6b;
}

.return {
    background-color: #93c5c5;
}


@media screen and (max-width: 800px) {
    // .single-order-wrapper {
    //     flex-direction: column;
    // }

    .customer {
        text-align: center;
    }

    .separator {
        display: none;
    }
}