.single-station-wrapper {
    display: flex;
    direction: rtl;

    .single-station {
        position: relative;
        display: flex;
        border-radius: 8px;
        background-color: #ffff;
        margin: 12px 6px 0px 0px;
        -webkit-box-shadow: -2px 5px 13px -10px rgba(0, 0, 0, 0.8);
        box-shadow: -2px 5px 13px -10px rgba(0, 0, 0, 0.8);
        direction: rtl;
        flex-grow: 1;
        min-width: 266px;

        .isAlive-indicator {
            position: absolute;
            width: 22px;
            height: 22px;
            left: 0;
            padding: 6px 6px;
        }

        .green {
            background-color: rgb(173, 255, 47);
            height: 86%;
            margin: 8px 0 0 4px;
            width: 8px;
            border-radius: 8px;
        }

        .red {
            background-color: red;
            height: 86%;
            margin: 8px 0 0 4px;
            width: 8px;
            border-radius: 8px;
        }

        .addres-and-navigation {
            display: flex;
            max-width: 50%;
            flex-grow: 1;

            .address-wraper {
                display: flex;
                flex-grow: 1;

                .address-section {
                    position: relative;
                    padding: 8px 12px;
                    line-height: 1.1;
                    min-height: 82px;
                    flex-grow: 1;

                    div:nth-child(1) {
                        font-size: 20px;
                    }

                    div:nth-child(2) {
                        font-size: 20px;
                    }

                    div:nth-child(3) {
                        font-size: 28px;
                    }

                    .img-task {
                        position: absolute;
                        top: 10px;
                        left: 0px;
                    }
                }
            }

        }



        .no-orders {
            display: flex;
            flex-wrap: wrap;
            max-width: 50%;
            flex-grow: 1;

            .text {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                font-size: 22px;
            }
        }


        .separator {
            border-right: 2px solid #F3F3F3;

        }

        .order-section {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            max-width: 48%;
            cursor: pointer;

            .driver-action {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                padding: 8px 12px;

                .action-name {
                    display: flex;
                    align-items: center;
                    font-size: 24px;

                    span {
                        margin-right: 12px;
                    }
                }

                .disabled-arrow {
                    filter: grayscale(100%);
                }

                .count {
                    display: flex;
                    align-items: center;
                    font-size: 36px;
                    line-height: 1;

                    .count-red {
                        color: red;
                    }

                    span {
                        margin-left: 3px;
                    }

                    .img-box {
                        margin-left: 20px;
                    }

                    .disabled-icon {
                        opacity: 0.4;
                        filter: grayscale(100%);
                    }
                }

                .cells-count {
                    font-size: 22px;
                }
            }
        }

    }

    .position {
        display: flex;

        .border {
            border-right: 1px solid #C2C2C2;
            margin: 0em -0.8em 0em 1em;
        }

        .position-icon {
            margin: 0.8em 0em 0em 0em;
            height: 34px;
            background-color: #F3F3F3;
            z-index: 2;
        }

        .point-disabled {
            pointer-events: none;
            color: #CECECE;
            border: 2px solid #C7C7C7;
            background-color: #fff;
            border-radius: 6px;
            height: 34px;
            width: 20px;
        }

        .point-black {
            background-color: #fff;
            border-radius: 6px;
            height: 34px;
            cursor: pointer;
        }
    }


    .yellow {
        background-color: rgb(248, 237, 98, 0.6) !important;
    }

    .disabled {
        color: #CECECE;
        border: 2px solid #CECECE;
        box-shadow: none;

        .text {
            color: black;
        }
    }

    .btn-wrapper {
        padding: 8px 8px;
        text-align: left;

        .btn-goto-marker {
            display: flex;
            border: 2px solid #F55A2A;
            padding: 4px 0;
            border-radius: 8px;
            cursor: pointer;
            justify-content: space-evenly;
            max-width: 68px;
            margin: 0 0 4px 0;
        }

        .btn-hold-station {
            max-width: 68px;
            font-size: 14px;
        }

        .nav-btns {
            display: flex;
        }

        .goto-icon {
            height: 29px;
        }
    }


}

@media screen and (max-width: 800px) {
    .address-section {
        padding: 8px 8px 8px 0px !important;
        max-width: 146px;
    }

    .img-task {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
    }
}