.completeTask-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;


    .completeTask {
        position: fixed;
        z-index: 3;
        top: 22%;
        left: 35%;
        background-color: white;
        padding: 22px 8px;
        font-size: 22px;
        width: 400px;
        max-height: 600px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: -1px 6px 18px -7px rgb(0 0 0 / 75%);

        .text-area-wrapper {
            text-align: center;
            margin-bottom: 18px;

            textarea {
                width: 98%;
                font-size: 26px;
            }
        }

        .error {
            color: red;
            font-weight: bold;
            text-align: center;
            margin-bottom: 8px;
        }

        .btn-holder {
            display: flex;
            justify-content: space-around;

            .file-input-container {
                position: relative;
                overflow: hidden;
                display: inline-block;
                min-height: 34px;
            }

            .file-input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;

            }

            .custom-file-button {
                border: 2px solid #F55726;
                padding: 4px 10px;
                border-radius: 8px;
                background-color: white;
                color: #F55726;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .btn-yesno {
            padding: 4px 42px;
        }

        .disabled {
            background-color: #f2f2f2 !important;
            border-color: #b3b3b3 !important;
            color: #b3b3b3 !important;
            cursor: not-allowed !important;
        }

    }

    @media (max-width: 800px) {
        .confirmPopup-wrapper {
            .confirmPopup {
                top: 10%;
                left: 10%;
                width: 80%;
                max-height: 80%;
            }
        }
    }
}