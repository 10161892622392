.orders-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;

    .orders-modal {
        position: fixed;
        z-index: 3;
        top: 8%;
        left: 6%;
        width: 88vw;
        background-color: white;
        border-radius: 12px;
        overflow: scroll;
        max-height: 90vh;
    }

    .header {
        display: flex;
        padding: 16px 46px;
        align-items: baseline;
        border-bottom: 2px solid #E7E7E7;

        .btn-close {
            color: #F55726;
            text-decoration: underline;
            font-size: 22px;
            margin-left: 32vw;
            cursor: pointer;
        }

        .title {
            font-size: 32px;
            text-decoration: underline;
        }
    }

    .content {
        overflow: scroll;
        max-height: 60vh;

        .btn-tasks {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            background-color: #f5f5f5;
            border: 1px solid #ccc;
            font-size: 22px;
            border-radius: 6px;

            div {
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    margin-left: 6px;
                }
            }

        }
    }

    .station-info {
        display: flex;
        margin: 20px 46px;

        img {
            height: 54px;
            width: 40pxpx;
            margin-left: 16px;
        }

        .adresses {
            display: flex;

            .nearby-text {
                font-size: 22px;
                text-decoration: underline;
            }

            .station-name {
                font-size: 32px;
                line-height: 0.8;
            }

            .station-address {
                font-size: 26px;
            }
        }

        .nearby-station {
            margin-right: 20vw;
        }

        .eng {
            direction: ltr;
        }

    }

    .orders-section {
        display: flex;
        justify-content: space-evenly;
        margin-top: 12px;

        .operation-text {
            display: flex;

            img {
                width: 20px;
                margin-left: 8px;
            }

            div {
                font-size: 34px;
            }
        }

        .orders-list-empty {
            font-size: 24px;
            direction: rtl;
            text-align: right;
        }
    }

    .orders-list {
        direction: ltr;
        overflow-y: auto;
        max-height: 44vh;
        padding: 0px 6px 0 0;
    }

    .orders-pickup {
        flex-grow: 1;
        padding: 0 8px 0 16px;
    }

    .orders-return {
        flex-grow: 1;
        padding: 0 8px 0 16px;
    }
}

.orders-list::-webkit-scrollbar {
    width: 10px;
    margin-left: 8px;
}

.orders-list::-webkit-scrollbar-track {
    background: #E2E2E2;
    border: 4px solid transparent;
    background-clip: content-box;

}

.orders-list::-webkit-scrollbar-thumb {
    background: #F55726;
    border-radius: 10px;
}




@media (max-width: 850px) {
    .content {
        max-height: 52vh !important;
    }

    .orders-section {
        flex-direction: column;
    }

    .orders-wrapper {
        .header .title {
            font-size: 22px;
        }

        .orders-section .orders-list-empty {
            text-align: right;
        }

        .header .btn-close {
            margin-left: 26vw;
        }
    }


}

// @media (min-width: 850px) {
//     .content {
//         //max-height: 78vh;
//     }

//     .orders-modal {
//         overflow: scroll;
//     }

//     .orders-section {
//         flex-direction: row;
//         padding: 0px 4px;
//     }
// }