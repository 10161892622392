.driver-map-container {
    display: flex;

    .drivermap-wrapper {
        display: flex;
        z-index: 0;

        .leaflet-container {
            width: 100%;
            z-index: 1;
        }

        .btn-minimize {
            position: fixed;
            z-index: 2;

            img {
                position: absolute;
                top: 20em;
                height: 120px;
            }
        }

        .leaflet-touch .leaflet-bar {
            display: inline-flex;
            direction: ltr;
            border: 2px solid #F55726;
            border-radius: 6px;
        }

        .leaflet-control-zoom-in {
            color: #F55726;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border: none;
        }

        .leaflet-control-zoom-out {
            color: #F55726;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: 2px solid;
            border-bottom: none;
        }

        .leaflet-touch .leaflet-bar a {

            line-height: 34px;
        }

        .leaflet-pane {
            z-index: 1;
        }

        .btn-center {
            position: absolute;
            z-index: 2;
            bottom: 5px;
            right: 90px;

            img {
                width: 35px;
            }
        }
    }

    .miniScreen {
        width: 4vw;
    }

    .maxScreen {
        width: 50vw;
    }

    .btn-horizonal-minimize {
        display: none;
    }
}


@media screen and (max-width: 800px) {
    .driver-map-container {
        background-color: #F3F3F3;
        margin: 0px 1px 0 0;
        position: sticky;
        bottom: 0;
        width: 98%;
        border: 8px solid #f3f3f3;
        z-index: 4;

        .drivermap-wrapper {
            border: 1px solid black;

        }
    }

    .btn-minimize {
        display: none;
    }

    .btn-horizonal-minimize {
        display: inline-block !important;
        position: absolute;
        top: 0;
        right: 44%;
        z-index: 12;

        button {
            padding: 5px 20px;
            background-color: #ff7400;
            color: #fff;
            border: none;
            border-radius: 0px 0px 12px 12px;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .minHeight {
        height: 4vh !important;
    }

    .maxHeight {
        height: 20vh !important;
    }

    .miniScreen {
        width: 100% !important;
    }

    .maxScreen {
        height: 20vh;
        width: 100% !important;
    }
}