.yesNoPopup-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .yesNoPopup {
        p {
            text-align: center;
        }

        position: fixed;
        z-index: 3;
        top: 22%;
        left: 35%;
        background-color: white;
        padding: 22px 8px;
        font-size: 22px;
        width: 400px;
        max-height: 600px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: -1px 6px 18px -7px rgb(0 0 0 / 75%);

        .btn-holder {
            display: flex;
            justify-content: space-around;
        }

        .btn-yesno {
            padding: 4px 42px;
        }
    }
}