.mainPage-wrapper {
    background-color: #F3F3F3;


    .mainPage-container {
        justify-content: right;
        direction: rtl;
    }

    .horizontal {
        display: flex;
        flex-direction: row;
        max-height: 100vh;
    }

    .vertical {
        display: flex;
        flex-direction: row;
        max-height: 100vh;
    }

    .btn-refresh-disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }

    .btn-refresh-routes {
        position: fixed;
        top: 18px;
        right: 8px;
        padding: 8px 4px;
        line-height: 1;
        z-index: 18;
    }

    .parallax {
        position: absolute;
        background: url('../../assets/images/city.png') top left;
        animation: backgroundMove 100s infinite linear;
        background-size: cover;
        background-repeat: round;
        content: "";
        top: 10%;
        left: 0%;
        height: 90vh;
        width: 68vw;
        border-right: 1px solid #C2C2C2;
        overflow: hidden;
    }

    .truck {
        position: absolute;
        z-index: 2;
        width: auto;
        height: 23%;
        left: 40%;
        animation: truckJump 2s infinite linear;
    }

    .no-location-text {
        background-color: #ffdddd;
        color: #333;
        border: 1px solid #ff6f6f;
        border-radius: 5px;
        padding: 16px;
        margin: 16px 0;
        line-height: 1.2;
    }

    .no-location-text p {
        margin: 8px 0;
    }

    @keyframes backgroundMove {
        0% {
            background-position: 0% 0;
        }

        100% {
            background-position: -68vw 0;
        }
    }

    @keyframes truckJump {
        0% {
            bottom: 11px;
        }

        50% {
            bottom: 12px;
        }

        100% {
            bottom: 11px;
        }
    }
}


@media screen and (max-width: 800px) {
    .mainPage-wrapper {
        .btn-refresh-routes {
            // top: 1.15rem;
            // right: 10.5rem;
            // padding: 10px 8px;
            // line-height: 1;
        }

        .horizontal {
            max-height: 100vh;
        }

        .vertical {
            display: flex;
            flex-direction: column !important;
        }

        .parallax {
            display: none;
        }
    }
}