.station-task {
    margin: 0 22px;

    .tasks-wrapper {
        overflow: scroll;
        max-height: 1000px;
        /* Adjust this value as needed */
        transition: max-height 0.6s ease-in-out;

        .border {
            border-left: 1px solid #CCCCCC;
            border-right: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            margin: 0 14px 0 14px;
            padding: 14px 0 8px 0;
            border-radius: 0 0 8px 8px;

            .task {
                display: flex;
                align-items: center;
                border: 1px solid #ccc;
                justify-content: space-between;
                border-radius: 12px;padding: 14px 16px;
                margin: 12px 30px 12px 30px;
                background-color: #f9f9f9;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                font-size: 28px;
            }
        }
    }

    .collapsed {
        max-height: 0;

    }
}

@media (max-width: 800px) {
    .task {
        flex-direction: column;
    }

    .btn-task-done {
        padding: 4px 12px;
    }
}